import { BackButton } from 'components/MainHeader/styles';
import BackButtonImage from 'assets/svg/icon-back.svg';
import { useNavigate } from 'react-router';
import { HeaderContainer, Logo } from './styles';

function Header(): JSX.Element {
  const navigate = useNavigate();
  return (
    <HeaderContainer>
      <BackButton data-testid="back-button" onClick={() => navigate(-1)}>
        <img src={BackButtonImage} alt="Voltar" />
      </BackButton>
      <Logo />
    </HeaderContainer>
  );
}

export { Header };
