import styled from 'styled-components';
import { Menu, Typography, Dropdown as DefaultDropdown } from 'antd';

export const Container = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 15px;
`;

export const BackButton = styled.a`
  color: black;
  font-weight: bold;
  font-size: 24px;
  margin-right: 15px;

  img {
    filter: brightness(0%);
  }
`;

export const Title = styled.h1`
  font-weight: 400;
  font-size: 23px;
  margin: 0.5rem 0;
  margin-right: auto;
  color: #101116;
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;

  > div {
    line-height: 15px;
    top: 2px;
    position: relative;
    margin-right: 10px;
  }

  div.roles {
    span {
      font-size: 13px;
      font-weight: 600;
      color: #535353;
    }
  }
`;

export const UsernameText = styled(Typography.Text)`
  margin-right: 0.5rem;
`;

export const Dropdown = styled(DefaultDropdown)`
  cursor: pointer;

  min-width: 180px;
`;

export const SignoutButton = styled<any>(Menu.Item)`
  color: red !important;
  margin: 0 12px;
  border-top: 0 !important;
  padding: 9px 0;
  padding-left: 3px;
`;

export const MenuItem = styled<any>(Menu.Item)`
  & + li {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
  }

  margin: 0 12px;
  padding: 9px 0;
  padding-left: 3px;
  position: relative;

  &::after {
    content: '>';
    color: #f46323;
    position: absolute;
    right: 3px;
  }
`;

export const IconsContainer = styled.div`
  margin-left: auto;
  margin-right: 20px;
`;
