import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { api } from 'services/api';
import {
  getCurrentEstablishment,
  removeCurrentEstablishment,
  storeCurrentEstablishment,
} from 'services/storage';
import { showError } from 'services/error';
import { Establishment, EstablishmentInput } from 'interfaces/establishment';

interface EstablishmentContextData {
  currentEstablishment: Establishment | null;
  setCurrentEstablishment: (
    establishment: Establishment,
    successCallback?: (() => void) | undefined,
  ) => void;
  clearCurrentEstablishment: () => void;
  create: (
    input: EstablishmentInput,
    imageData: File | null,
    successCallback?: (() => void) | undefined,
  ) => Promise<void>;
  update: (
    input: EstablishmentInput,
    imageData: File | null,
    successCallback?: (() => void) | undefined,
    isToRemoveImage?: boolean | undefined,
  ) => Promise<void>;
}

interface IEstablishmentProviderProps {
  children: JSX.Element | JSX.Element[];
}

const EstablishmentContext = createContext({} as EstablishmentContextData);

function EstablishmentProvider({
  children,
}: IEstablishmentProviderProps): JSX.Element {
  const [currentEstablishment, setInternalCurrentEstablishment] =
    useState<Establishment | null>(() => {
      return getCurrentEstablishment();
    });

  const setCurrentEstablishment = useCallback(
    (establishment: Establishment, successCallback?: () => void) => {
      storeCurrentEstablishment(establishment);
      setInternalCurrentEstablishment(establishment);

      successCallback?.();
    },
    [],
  );

  const clearCurrentEstablishment = useCallback(() => {
    setInternalCurrentEstablishment(null);
    removeCurrentEstablishment();
  }, []);

  const uploadImage = async (
    imageData: File | null,
    establishmentId: string,
    isToRemoveImage?: boolean,
  ) => {
    if (imageData) {
      const formData = new FormData();

      formData.append('logo', imageData);

      const { data } = await api.put(
        `trade/establishments/${establishmentId}/logo`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      return data.data;
    }

    if (isToRemoveImage === true) {
      await api.delete(`trade/establishments/${establishmentId}/logo`);
    }

    return null;
  };

  const create = useCallback(
    async (
      input: EstablishmentInput,
      imageData: File | null,
      successCallback?: () => void,
    ) => {
      try {
        const { data } = await api.post('trade/establishments', input);

        try {
          await uploadImage(imageData, data.data.id);
        } catch {
          showError('Ocorreu um erro com a atualização da imagem.');
        }

        if (successCallback) successCallback();
      } catch (error: any) {
        showError('Ocorreu um erro com a requisição. Tente novamente.');
      }
    },
    [],
  );

  const update = useCallback(
    async (
      input: EstablishmentInput,
      imageData: File | null,
      successCallback?: () => void,
      isToRemoveImage?: boolean,
    ) => {
      try {
        const { data } = await api.put(
          `trade/establishments/${input.id}`,
          input,
        );

        try {
          const logo = await uploadImage(
            imageData,
            data.data.id,
            isToRemoveImage,
          );

          data.data.logo = logo;

          const updatedEstablishment = currentEstablishment
            ? {
                employees: currentEstablishment.employees,
                ...data.data,
              }
            : null;

          storeCurrentEstablishment(updatedEstablishment);
          setInternalCurrentEstablishment(updatedEstablishment);

          if (successCallback) successCallback();
        } catch {
          showError(
            'As alterações foram salvas mas ocorreu um erro com a atualização da imagem.',
          );
        }
      } catch (error: any) {
        showError('Ocorreu um erro com a requisição. Tente novamente.');
      }
    },
    [currentEstablishment],
  );

  const mountValue = useMemo(
    () => ({
      currentEstablishment,
      setCurrentEstablishment,
      clearCurrentEstablishment,
      create,
      update,
    }),
    [
      currentEstablishment,
      setCurrentEstablishment,
      clearCurrentEstablishment,
      create,
      update,
    ],
  );

  return (
    <EstablishmentContext.Provider value={mountValue}>
      {children}
    </EstablishmentContext.Provider>
  );
}

function useEstablishment(): EstablishmentContextData {
  const context = useContext(EstablishmentContext);

  if (!context) {
    throw new Error(
      'useEstablishment must be used within a EstablishmentProvider',
    );
  }

  return context;
}

export { EstablishmentProvider, useEstablishment };
