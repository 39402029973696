import { HeaderContainer } from '../styles';
import { Title } from './styles';

function Header(): JSX.Element {
  return (
    <HeaderContainer>
      <Title>Login</Title>
    </HeaderContainer>
  );
}

export { Header };
