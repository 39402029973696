import { useNavigate } from 'react-router-dom';
import { Menu, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import BackButtonImage from 'assets/svg/icon-back.svg';

import { useEstablishment } from 'hooks/useEstablishment';
import { useAuth } from 'hooks/useAuth';

import {
  BackButton,
  Title,
  Container,
  UsernameText,
  Dropdown,
  SignoutButton,
  MenuItem,
} from './styles';

export interface MainHeaderProps {
  title: string;
  showBackButton?: boolean;
}

const getShortName = (name: string) => {
  const nameArray = name.split(' ');
  if (nameArray.length === 1) return nameArray[0];
  return `${nameArray[0]} ${nameArray[nameArray.length - 1]}`;
};

function MainHeader({ title, showBackButton = false }: MainHeaderProps) {
  const { clearCurrentEstablishment } = useEstablishment();
  const navigate = useNavigate();
  const { loggedUser, logout } = useAuth();

  const handleProfile = () => {
    navigate('/profile');
  };

  const handleAdminArea = () => {
    clearCurrentEstablishment();
    navigate('/');
  };

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const DropdownMenu = (
    <Menu>
      <MenuItem onClick={handleAdminArea}>Área do Admin</MenuItem>
      <MenuItem onClick={handleProfile}>Editar perfil</MenuItem>
      <SignoutButton onClick={handleLogout}>Desconectar</SignoutButton>
    </Menu>
  );

  return (
    <Container data-testid="main-header">
      {showBackButton && (
        <BackButton data-testid="back-button" onClick={() => navigate(-1)}>
          <img src={BackButtonImage} alt="Voltar" />
        </BackButton>
      )}
      <Title data-testid="title">{title}</Title>
      {loggedUser && (
        <div data-testid="user-info">
          <Dropdown
            trigger={['click']}
            overlay={DropdownMenu}
            placement="bottomLeft"
          >
            <div>
              <UsernameText strong>
                {getShortName(loggedUser.name)}
              </UsernameText>
              <Avatar
                src={loggedUser.avatar?.location}
                size="large"
                icon={<UserOutlined />}
              />
            </div>
          </Dropdown>
        </div>
      )}
    </Container>
  );
}

export default MainHeader;
