import MainDrawer from 'components/MainDrawer';
import MainHeader from 'components/MainHeader';
import LoadingImg from 'assets/images/loading.gif';

import TopNavigation, { TopNavigationTab } from '../TopNavigation';

import {
  Container,
  Wrapper,
  Content,
  InnerContent,
  TopFixed,
  LoadingContainer,
  Footer,
} from './styles';

export interface PageProps {
  title: string;
  displayDrawer?: boolean;
  children?: React.ReactNode;
  tabs?: TopNavigationTab[];
  SubHeader?: React.ReactNode;
  footer?: React.ReactNode;
  loading?: boolean;
  showBackButton?: boolean;
}

function Page({
  title,
  tabs,
  children,
  displayDrawer = true,
  SubHeader,
  footer,
  loading,
  showBackButton = false,
}: PageProps): JSX.Element {
  return (
    <Container data-testid="page">
      {displayDrawer && <MainDrawer data-testid="main-drawer" />}
      <Wrapper>
        <TopFixed className="topFixed">
          <div className="headerContainer">
            <MainHeader title={title} showBackButton={showBackButton} />
            {tabs && <TopNavigation data-testid="top-navigation" tabs={tabs} />}
          </div>
        </TopFixed>
        {SubHeader && <div className="subHeader">{SubHeader}</div>}
        <Content>
          <InnerContent>
            {loading ? (
              <LoadingContainer data-testid="loading">
                <img src={LoadingImg} alt="Carregando" />
              </LoadingContainer>
            ) : (
              children
            )}
          </InnerContent>
        </Content>
        {footer && <Footer>{footer}</Footer>}
      </Wrapper>
    </Container>
  );
}

export default Page;
