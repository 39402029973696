import ImagePlaceholderIcon from 'assets/svg/fanapp-icon-image-placeholder.svg';

import { Container } from './styles';

function ImagePlaceholder({ className }: { className?: string }) {
  return (
    <Container className={className}>
      <img src={ImagePlaceholderIcon} alt="Item sem imagem" />
    </Container>
  );
}

export { ImagePlaceholder };
