import { EmailForm } from './EmailForm';
import { PhoneForm } from './PhoneForm';

import { FormContainer } from '../styles';
import { Tabs, TabBarItem } from './styles';

import { Header } from './Header';
import { LoginLayout } from '../LoginLayout';

const { TabPane } = Tabs;

function LoginForm(): JSX.Element {
  return (
    <LoginLayout>
      <Header />
      <FormContainer>
        <Tabs defaultActiveKey="1" tabBarGutter={0}>
          <TabPane key="1" tab={<TabBarItem>Email</TabBarItem>}>
            <EmailForm />
          </TabPane>
          <TabPane key="2" tab={<TabBarItem>Telefone</TabBarItem>}>
            <PhoneForm />
          </TabPane>
        </Tabs>
      </FormContainer>
    </LoginLayout>
  );
}

export { LoginForm };
