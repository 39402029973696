import { memo } from 'react';
import { useNavigate } from 'react-router';
import CloseIcon from 'assets/svg/close-icon.svg';
import AlertIcon from 'assets/svg/red-alert.svg';

import {
  Modal,
  ButtonContainer,
  Button,
  CloseContainer,
  CloseButton,
} from './styles';

interface ModalProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

function ConfirmModal({ visible, setVisible }: ModalProps) {
  const navigate = useNavigate();

  return (
    <Modal
      title=""
      footer={false}
      visible={visible}
      destroyOnClose
      closable
      onCancel={() => {
        setVisible(false);
      }}
    >
      <CloseContainer>
        <CloseButton onClick={() => setVisible(false)}>
          <img src={CloseIcon} alt="Fechar" />
        </CloseButton>
      </CloseContainer>

      <img className="alert-image" src={AlertIcon} alt="Alerta" />

      <h1>Tem certeza que deseja cancelar essa operação?</h1>

      <p>Todos os ícones serão deletados se continuar.</p>

      <ButtonContainer>
        <Button
          onClick={() => navigate('category-icon-management')}
          htmlType="button"
          type="primary"
        >
          Confirmar
        </Button>

        <Button
          onClick={() => setVisible(false)}
          htmlType="button"
          type="default"
        >
          Cancelar
        </Button>
      </ButtonContainer>
    </Modal>
  );
}

export default memo(ConfirmModal);
