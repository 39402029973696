import { notification } from 'antd';
import { Errors } from 'constants/messages';
import { CategoryIcon } from 'interfaces/categoryIcon';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { api } from 'services/api';

export const useIconManagement = () => {
  const [loading, setLoading] = useState(true);
  const [editIconModalVisible, setEditIconModalVisible] = useState(false);
  const [categoryIcons, setCategoryIcons] = useState<CategoryIcon[]>([]);
  const [selectedIcon, setSelectedIcon] = useState<CategoryIcon | undefined>();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.get('/admin/category-icons');

        setCategoryIcons(response.data.data);
      } catch {
        notification.error({
          message: Errors.request_error_title,
          description: Errors.request_error_description,
        });
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return {
    navigate,
    loading,
    editIconModalVisible,
    setEditIconModalVisible,
    categoryIcons,
    selectedIcon,
    setSelectedIcon,
  };
};
