import { useState, useEffect, useCallback } from 'react';

import Page from 'components/Page';
import DataTable from 'components/DataTable';

import { notification } from 'antd';

import { api } from 'services/api';
import { Errors } from 'constants/messages';
import { Establishment } from 'interfaces/establishment';
import { formatCnpj, formatCpf } from 'helpers/stringHelper';
import { format, parseISO } from 'date-fns';
import { defaultPageSize } from 'constants/styles';
import { Content } from './styles';

function EstablishmentList() {
  const [rawEstablishments, setRawEstablishments] = useState<
    Array<Establishment>
  >([]);
  const [establishments, setEstablishments] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(true);
  const [paginationTotal, setPaginationTotal] = useState(0);

  const columns: any = [
    {
      title: 'Nome',
      dataIndex: 'companyName',
    },
    {
      title: 'Documento',
      dataIndex: 'document',
    },
    {
      title: 'Data de criação',
      dataIndex: 'createdAt',
    },
  ];

  const handleChangeTable = useCallback(
    async (page?: number | undefined, pageSize?: number | undefined) => {
      try {
        setLoading(true);
        const response = await api.get(
          `admin/establishments?page=${page || 1}&pageSize=${
            pageSize || defaultPageSize
          }`,
        );

        const total = response.headers['x-total'] || 0;
        setPaginationTotal(Number(total));

        setRawEstablishments(response.data.data);
      } catch {
        notification.error({
          message: Errors.request_error_title,
          description: Errors.request_error_description,
        });
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  useEffect(() => {
    (async () => {
      handleChangeTable();
    })();
  }, [handleChangeTable]);

  useEffect(() => {
    setEstablishments(
      rawEstablishments.map((x: Establishment) => ({
        ...x,
        document:
          x.document?.length > 11
            ? formatCnpj(x.document)
            : formatCpf(x.document),
        createdAt: format(parseISO(x.createdAt), 'dd/MM/yyyy'),
      })),
    );
  }, [rawEstablishments]);

  return (
    <Page
      displayDrawer
      title="Estabelecimento"
      tabs={[
        {
          label: 'Gestão de estabelecimentos',
          route: '/establishment',
        },
      ]}
    >
      <Content>
        <DataTable
          total={paginationTotal}
          loading={loading}
          onChange={handleChangeTable}
          columns={columns}
          dataSource={establishments}
          rowKey="id"
          hasActionsColumn={false}
        />
      </Content>
    </Page>
  );
}

export { EstablishmentList };
