import { EstablishmentProvider } from './useEstablishment';
import { AuthProvider } from './useAuth';
import { UserProvider } from './useUser';
import { PermissionProvider } from './usePermission';

interface AppProviderProps {
  children: JSX.Element | JSX.Element[];
}

export function AppProvider({ children }: AppProviderProps): JSX.Element {
  return (
    <EstablishmentProvider>
      <AuthProvider>
        <UserProvider>
          <PermissionProvider>{children}</PermissionProvider>
        </UserProvider>
      </AuthProvider>
    </EstablishmentProvider>
  );
}
