import { useCallback, useState } from 'react';
import { Form, Input, Row, Checkbox } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { useQuery } from 'hooks/useQuery';
import { LoginInput, useAuth } from 'hooks/useAuth';
import { SubmitButton } from '../styles';
import { ForgetPasswordLink } from './styles';

function EmailForm(): JSX.Element {
  const { login } = useAuth();
  const query = useQuery();
  const [loading, setLoading] = useState(false);
  const [redirectUrl] = useState<string | null>(query.get('redirect'));

  const handleForm = useCallback(
    async ({ email, password, persist }: Store) => {
      setLoading(true);
      const input: LoginInput = {
        email,
        password,
      };

      await login(input, persist, () => {
        if (redirectUrl) window.location.href = redirectUrl;
      });

      setLoading(false);
    },
    [redirectUrl, login],
  );

  return (
    <Form onFinish={handleForm}>
      <label className="custom-label">E-mail</label>
      <Form.Item
        name="email"
        validateFirst={false}
        rules={[
          {
            required: true,
            message: 'O campo email é obrigatório',
            validateTrigger: 'onBlur',
          },
          {
            type: 'email',
            message: 'Email inválido',
            validateTrigger: 'onBlur',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <label className="custom-label">Senha</label>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'O campo senha é obrigatório',
            validateTrigger: 'onBlur',
          },
          {
            min: 6,
            message: 'O campo senha deve conter no mínimo 6 caracteres',
            validateTrigger: 'onBlur',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Row justify="space-between" align="middle">
        <Form.Item valuePropName="checked" name="persist">
          <Checkbox>Manter conectado</Checkbox>
        </Form.Item>

        <ForgetPasswordLink href={`/forgot-password?redirect=${redirectUrl}`}>
          Esqueceu a senha?
        </ForgetPasswordLink>
      </Row>

      <Row justify="center">
        <SubmitButton
          disabled={loading}
          htmlType="submit"
          type="primary"
          size="large"
        >
          {loading ? 'Enviando' : 'Confirmar'}
        </SubmitButton>
      </Row>
    </Form>
  );
}

export { EmailForm };
