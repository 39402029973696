import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  background: transparent;
  border-radius: 5px;

  img {
    height: 100%;
  }
`;
