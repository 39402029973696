import { useMemo } from 'react';
import { IconType } from '../..';
import {
  Container,
  ImageContainer,
  Image,
  NameContainer,
  Name,
  RemoveButton,
  RemoveIcon,
} from './styles';

function FileCard({
  onRemoveIcon,
  file,
}: {
  onRemoveIcon: (id: string) => void;
  file: IconType;
}) {
  const formatName = useMemo(() => file.name.split('.')[0], [file]);

  return (
    <Container>
      <ImageContainer>
        <Image src={file.preview} />
      </ImageContainer>
      <NameContainer>
        <Name title={formatName}>{formatName}</Name>
      </NameContainer>
      <RemoveButton type="button" onClick={() => onRemoveIcon(file.key!)}>
        <RemoveIcon />
      </RemoveButton>
    </Container>
  );
}

export { FileCard };
