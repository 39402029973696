import styled from 'styled-components';
import { Modal as antdModal, Button as AntdButton } from 'antd';

export const Modal = styled(antdModal)`
  width: 400px !important;

  img.alert-image {
    width: 66px;
    margin: 0 auto 30px;
    display: block;
  }

  h1 {
    font-weight: 600;
    font-size: 23px;
    line-height: 28px;
    color: var(--title);
    text-align: center;
    margin-bottom: 10px;
  }

  p {
    text-align: center;
    color: var(--text);
  }

  span.ant-modal-close-x {
    display: none;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 30px;
`;

export const Button = styled(AntdButton)`
  text-shadow: none;
  box-shadow: none;
  width: 100%;
  font-size: 19px;

  & + & {
    margin-left: 15px;
  }
`;

export const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CloseButton = styled.div`
  cursor: pointer;
`;
