import { notification } from 'antd';
import { ApiErrors, Errors } from 'constants/messages';
import { CategoryIcon } from 'interfaces/categoryIcon';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { api } from 'services/api';

export const useEditIconModal = (
  icon: CategoryIcon,
  visible: boolean,
  setVisible: (visible: boolean) => void,
) => {
  const [data, setData] = useState(icon);
  const [loading, setLoading] = useState(false);
  const [imageData, setImageData] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | undefined>('');
  const [disabled, setDisabled] = useState(!!icon.disabledAt);
  const navigate = useNavigate();

  useEffect(() => {
    setData(icon);
    setDisabled(!!icon.disabledAt);
    setImagePreview(icon.icon.location);
  }, [icon, visible]);

  async function handleSubmit() {
    try {
      setLoading(true);
      let edited = false;

      if (disabled !== !!data.disabledAt) {
        await api.post(
          `/admin/category-icons/${data.id}/${disabled ? 'disable' : 'enable'}`,
        );
        edited = true;
      }

      if (imageData) {
        const formData = new FormData();
        formData.append('categoryIcon', imageData);

        await api.put(`/admin/category-icons/${data.id}/image`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        edited = true;
      }

      if (edited) {
        notification.success({
          message: 'Sucesso',
          description: 'Alterações realizadas com sucesso.',
        });
        navigate(0);
      } else {
        setVisible(false);
      }
    } catch {
      notification.error({
        message: Errors.request_error_title,
        description: Errors.request_error_description,
      });
    } finally {
      setLoading(false);
    }
  }

  async function handleDelete() {
    try {
      setLoading(true);

      await api.delete(`/admin/category-icons/${data.id}`);

      notification.success({
        message: 'Sucesso',
        description: 'Ícone deletado com sucesso.',
      });
      navigate(0);
    } catch (err: any) {
      if (
        err.response?.data?.error?.code ===
        ApiErrors.HasEstablishmentProductCategoryConstraintError
      ) {
        notification.error({
          message: 'Erro!',
          description:
            'O ícone não pôde ser deletado pois está sendo utilizado',
        });
      } else {
        notification.error({
          message: Errors.request_error_title,
          description: Errors.request_error_description,
        });
      }
    } finally {
      setLoading(false);
    }
  }

  const handleImageDrop = useCallback(acceptedFiles => {
    const imgData = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = () => {
      setImagePreview(reader?.result?.toString());
      setImageData(imgData);
    };
    reader.readAsDataURL(imgData);
  }, []);

  const formatName = () => {
    const split = imageData?.name.split('.');

    return split?.slice(0, split.length - 1)[0] || data.name;
  };

  return {
    loading,
    disabled,
    imagePreview,
    data,
    setData,
    setDisabled,
    handleSubmit,
    handleDelete,
    handleImageDrop,
    formatName,
  };
};
