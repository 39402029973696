import { TranslateIcons, translateIcons } from 'constants/translate';
import { capitalize } from 'helpers/stringHelper';
import { CategoryIcon } from 'interfaces/categoryIcon';
import { useMemo } from 'react';

import {
  Container,
  StatusBadge,
  ImageContainer,
  Image,
  DescriptionContainer,
  DescriptionTitle,
  DescriptionText,
  EditButton,
  EditIcon,
} from './styles';

interface CategoryCardProps {
  categoryIcon: CategoryIcon;
  onEdit: () => void;
}

function IconCard({ categoryIcon, onEdit }: CategoryCardProps) {
  const capitalizeIconName = useMemo(() => {
    const value =
      translateIcons[
        categoryIcon.name.replace('-', '_') as keyof TranslateIcons
      ] || categoryIcon.name;
    return capitalize(value);
  }, [categoryIcon]);

  return (
    <Container>
      {!categoryIcon.disabledAt && <StatusBadge />}
      <ImageContainer>
        <Image src={categoryIcon.icon.location} />
      </ImageContainer>
      <DescriptionContainer>
        <DescriptionTitle title={capitalizeIconName}>
          {capitalizeIconName}
        </DescriptionTitle>
        <DescriptionText title={categoryIcon.name}>
          {categoryIcon.name}
        </DescriptionText>
      </DescriptionContainer>
      <EditButton type="button" onClick={onEdit}>
        <EditIcon />
      </EditButton>
    </Container>
  );
}

export { IconCard };
