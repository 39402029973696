import { Login } from 'pages/Login';
import { useAuth } from 'hooks/useAuth';
import { AuthRoutes } from './auth.routes';

function Routes(): JSX.Element {
  const { token } = useAuth();

  return token ? <AuthRoutes /> : <Login />;
}

export { Routes };
