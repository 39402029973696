import { Modules } from 'constants/modules';
import { Roles } from 'constants/roles';
import { StorageKeys } from 'constants/storage';
import { Establishment, Role } from 'interfaces/establishment';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { api } from 'services/api';
import { storeCurrentEstablishment } from 'services/storage';

interface PermissionContextData {
  addCurrentEstablishment: (establishment: Establishment) => void;
  clearCurrentEstablishment: () => void;
  refreshCurrentEstablishment: (establishmentId: string) => void;
  has: (
    modules: Array<Modules>,
    roles?: Array<Roles>,
    establishment?: Establishment | null,
  ) => boolean;
  currentEstablishment: Establishment;
}

interface IPermissionProviderProps {
  children: JSX.Element | JSX.Element[];
}

const PermissionContext = createContext({} as PermissionContextData);

function PermissionProvider({
  children,
}: IPermissionProviderProps): JSX.Element {
  const [currentEstablishment, setCurrentEstablishment] =
    useState<Establishment>(() => {
      const serializedEstablishment = localStorage.getItem(
        StorageKeys.CURRENT_ESTABLISHMENT,
      );
      if (!serializedEstablishment) return null;
      return JSON.parse(serializedEstablishment);
    });

  const addCurrentEstablishment = useCallback(establishment => {
    setCurrentEstablishment(establishment);
    storeCurrentEstablishment(establishment);
  }, []);

  const clearCurrentEstablishment = useCallback(() => {
    setCurrentEstablishment({} as Establishment);
  }, []);

  const refreshCurrentEstablishment = useCallback(
    async establishmentId => {
      try {
        const {
          data: { data },
        } = await api.get(
          `trade/establishments/${establishmentId}?integrations=true&roles=true`,
        );

        addCurrentEstablishment(data);
      } catch {
        //
      }
    },
    [addCurrentEstablishment],
  );

  const has = useCallback(
    (modules = [], roles = [], establishment = null) => {
      const userRoles = establishment?.employees
        ? establishment?.employees[0]?.roles?.map((x: Role) =>
            x.name.toLowerCase(),
          )
        : currentEstablishment?.employees
        ? currentEstablishment?.employees[0]?.roles.map(x =>
            x.name.toLowerCase(),
          )
        : [];

      let allow =
        !roles || !userRoles
          ? true
          : roles.some((x: string) => userRoles.indexOf(x.toLowerCase()) > -1);

      function checkStockModule() {
        const stockIntegration = currentEstablishment?.integrations?.stock;
        return stockIntegration && !stockIntegration.disabledAt;
      }

      modules.forEach((x: string) => {
        switch (x) {
          case Modules.STOCK:
            allow = checkStockModule();
            break;
          default:
            break;
        }
      });

      return allow;
    },
    [currentEstablishment],
  );

  const mountValue = useMemo(
    () => ({
      addCurrentEstablishment,
      refreshCurrentEstablishment,
      clearCurrentEstablishment,
      has,
      currentEstablishment,
    }),
    [
      addCurrentEstablishment,
      clearCurrentEstablishment,
      currentEstablishment,
      has,
      refreshCurrentEstablishment,
    ],
  );

  return (
    <PermissionContext.Provider value={mountValue}>
      {children}
    </PermissionContext.Provider>
  );
}

function usePermission(): PermissionContextData {
  const context = useContext(PermissionContext);

  if (!context) {
    throw new Error('usePermission must be used within a PermissionProvider');
  }

  return context;
}

export { PermissionProvider, usePermission };
