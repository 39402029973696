import { useCallback, useState } from 'react';
import { Form, Row, Input, Checkbox } from 'antd';
import { Store } from 'antd/lib/form/interface';
import PhoneInput from 'react-phone-input-2';
import { useQuery } from 'hooks/useQuery';
import { LoginInput, useAuth } from 'hooks/useAuth';
import { SubmitButton } from '../styles';
import { ForgetPasswordLink } from './styles';

function PhoneForm(): JSX.Element {
  const { login } = useAuth();
  const query = useQuery();
  const [loading, setLoading] = useState(false);
  const [redirectUrl] = useState<string | null>(query.get('redirect'));

  const handleForm = useCallback(
    async ({ phone, password, persist }: Store) => {
      setLoading(true);

      const input: LoginInput = {
        phone: `+${phone}`,
        password,
      };

      await login(input, persist, () => {
        if (redirectUrl) window.location.href = redirectUrl;
      });

      setLoading(false);
    },
    [redirectUrl, login],
  );

  return (
    <Form onFinish={handleForm}>
      <label className="custom-label">Telefone</label>
      <Form.Item
        name="phone"
        validateFirst
        rules={[
          {
            required: true,
            message: 'O campo telefone é obrigatório',
            validateTrigger: 'onBlur',
          },
          {
            min: 12,
            message:
              'Seu telefone deve conter no mínimo 12 dígitos, incluindo código do pais e DDD',
            validateTrigger: 'onBlur',
          },
        ]}
      >
        <PhoneInput country="br" inputClass="ant-input" />
      </Form.Item>

      <label className="custom-label">Senha</label>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'O campo senha é obrigatório',
            validateTrigger: 'onBlur',
          },
          {
            min: 6,
            message: 'O campo senha deve conter no mínimo 6 caracteres',
            validateTrigger: 'onBlur',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Row justify="space-between" align="middle">
        <Form.Item valuePropName="checked" name="persist">
          <Checkbox>Manter conectado</Checkbox>
        </Form.Item>

        <ForgetPasswordLink href={`/forgot-password?redirect=${redirectUrl}`}>
          Esqueceu a senha?
        </ForgetPasswordLink>
      </Row>

      <Row justify="center">
        <SubmitButton
          disabled={loading}
          htmlType="submit"
          type="primary"
          size="large"
        >
          {loading ? 'Enviando' : 'Confirmar'}
        </SubmitButton>
      </Row>
    </Form>
  );
}

export { PhoneForm };
