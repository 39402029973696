import { notification } from 'antd';
import { Errors } from 'constants/messages';
import { CategoryIcon } from 'interfaces/categoryIcon';
import { useEffect, useState } from 'react';
import { api } from 'services/api';

export const useAddIconModal = () => {
  const [search, setSearch] = useState('');
  const [categoryIcons, setCategoryIcons] = useState<CategoryIcon[]>([]);
  const [data, setData] = useState<CategoryIcon[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get('/admin/category-icons');

        setCategoryIcons(response.data.data);
        setData(response.data.data);
      } catch {
        notification.error({
          message: Errors.request_error_title,
          description: Errors.request_error_description,
        });
      }
    })();
  }, []);

  useEffect(() => {
    if (search) {
      setData(categoryIcons.filter(x => x.name.includes(search)));
    } else {
      setData(categoryIcons);
    }
  }, [search, categoryIcons]);

  return {
    setSearch,
    data,
  };
};
