import { Input, Row } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import Page from 'components/Page';
import { gutter } from 'constants/styles';
import { Category } from 'interfaces/category';
import TrashIcon from 'assets/svg/trash.svg';
import { useNavigate } from 'react-router';
import AddIconModal from '../AddIconModal';
import {
  Column,
  AddIconButton,
  PlaceholderIcon,
  Separator,
  AddMoreButton,
  FormRow,
  Header,
  Button,
} from './styles';
import { tabRoutes } from '../../tabRoutes';
import { useCategoryForm } from './useCategoryForm';

function CategoryForm() {
  const {
    loading,
    categories,
    addIconModalVisible,
    selectedIndex,
    setCategories,
    setAddIconModalVisible,
    handleSubmit,
    handleOpenModal,
    handleRemoveCategory,
  } = useCategoryForm();

  const navigate = useNavigate();

  return (
    <Page
      title="Categorias"
      tabs={tabRoutes}
      SubHeader={
        <Header>
          <Button
            onClick={() => {
              navigate(-1);
            }}
            htmlType="button"
            type="default"
          >
            Cancelar
          </Button>

          <Button
            disabled={loading}
            onClick={() => handleSubmit()}
            htmlType="submit"
            type="primary"
          >
            {loading ? 'Salvando' : 'Salvar'}
          </Button>
        </Header>
      }
    >
      <Row gutter={gutter} className="mb-section">
        <Column sm={24} lg={12}>
          <h1>Adicione novas categorias</h1>
          <p>
            Adicione um ícone e um título para a categoria, assim elas estarão
            <br />
            disponíveis para serem usados pelos clientes do sistema FanPDV.
          </p>
        </Column>
      </Row>
      <Row gutter={gutter} className="mb-section">
        <Column sm={24} lg={12}>
          {categories.map((x, index) => (
            <FormRow key={x.id}>
              {index > 0 && <Separator />}
              {index > 0 && (
                <button
                  type="button"
                  className="remove-button"
                  onClick={() => {
                    handleRemoveCategory(x.id!);
                  }}
                >
                  <img src={TrashIcon} alt="Remover" className="trash-icon" />
                </button>
              )}
              {x.categoryIcon ? (
                <PlaceholderIcon onClick={() => handleOpenModal(index)}>
                  <img
                    src={x.categoryIcon.icon.location}
                    alt={x.categoryIcon.icon.name || 'default'}
                  />
                </PlaceholderIcon>
              ) : (
                <AddIconButton
                  htmlType="button"
                  type="link"
                  onClick={() => handleOpenModal(index)}
                >
                  <span className="plus-icon">+</span> Adicionar ícone
                </AddIconButton>
              )}
              <label>Título</label>

              <Input
                value={x.name}
                onChange={e => {
                  setCategories(prevState =>
                    prevState.map((y, i) =>
                      i === index ? { ...y, name: e.target.value } : y,
                    ),
                  );
                }}
              />
            </FormRow>
          ))}
          <AddMoreButton
            onClick={() => {
              setCategories(prevState => [
                ...prevState,
                { id: uuidv4() } as Category,
              ]);
            }}
          >
            <div className="plus-icon">+</div> Adicionar nova categoria
          </AddMoreButton>
        </Column>
      </Row>

      <AddIconModal
        visible={addIconModalVisible}
        setVisible={setAddIconModalVisible}
        setIcon={icon => {
          setCategories(prevState =>
            prevState.map((x, index) =>
              index === selectedIndex ? { ...x, categoryIcon: icon } : x,
            ),
          );
        }}
      />
    </Page>
  );
}

export { CategoryForm };
