import { memo } from 'react';
import { Input, Switch } from 'antd';
import { Category } from 'interfaces/category';
import TrashRedIcon from 'assets/svg/trash-red.svg';
import CloseIcon from 'assets/svg/close-icon.svg';
import { ImagePlaceholder } from 'components/ImagePlaceholder';
import AddIconModal from '../AddIconModal';
import {
  Modal,
  PlaceholderIcon,
  ControlsContainer,
  SwitchContainer,
  DeleteButton,
  Button,
  CloseContainer,
  CloseButton,
} from './styles';
import { useEditCategoryModal } from './useEditCategoryModal';

interface ModalProps {
  category: Category;
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

function EditCategoryModal({ category, visible, setVisible }: ModalProps) {
  const {
    addIconModalVisible,
    loading,
    disabled,
    name,
    setName,
    setCategoryIcon,
    setAddIconModalVisible,
    setDisabled,
    handleSubmit,
    handleDelete,
  } = useEditCategoryModal(category, setVisible);

  return (
    <Modal
      title=""
      footer={false}
      visible={visible}
      destroyOnClose
      closable
      onCancel={() => {
        setVisible(false);
      }}
    >
      <CloseContainer>
        <CloseButton onClick={() => setVisible(false)}>
          <img src={CloseIcon} alt="Fechar" />
        </CloseButton>
      </CloseContainer>
      <h1>Editar categoria</h1>

      <PlaceholderIcon onClick={() => setAddIconModalVisible(true)}>
        {category.categoryIcon ? (
          <img
            src={category.categoryIcon.icon.location}
            alt={category.categoryIcon.icon.name || 'default'}
          />
        ) : (
          <ImagePlaceholder />
        )}
      </PlaceholderIcon>

      <label>Título</label>

      <Input
        value={name}
        onChange={e => {
          setName(e.target.value);
        }}
      />

      <ControlsContainer>
        <SwitchContainer>
          <Switch
            checked={!disabled}
            onChange={() => {
              setDisabled(!disabled);
            }}
          />
          <span>Habilitar para uso</span>
        </SwitchContainer>
        <DeleteButton onClick={handleDelete} disabled={loading}>
          <img src={TrashRedIcon} alt="Remover" className="trash-icon" />
          <span>Deletar categoria</span>
        </DeleteButton>
      </ControlsContainer>

      <Button
        onClick={handleSubmit}
        htmlType="button"
        type="primary"
        disabled={loading}
      >
        {loading ? 'Salvando' : 'Concluir'}
      </Button>

      <AddIconModal
        visible={addIconModalVisible}
        setVisible={setAddIconModalVisible}
        setIcon={icon => {
          setCategoryIcon(icon);
        }}
      />
    </Modal>
  );
}

export default memo(EditCategoryModal);
