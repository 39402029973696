import { notification } from 'antd';
import { Errors } from 'constants/messages';
import { Category } from 'interfaces/category';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { api } from 'services/api';

export const useCategoryManagement = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [editCategoryModalVisible, setEditCategoryModalVisible] =
    useState(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<
    Category | undefined
  >();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.get('/admin/product-categories');

        setCategories(response.data.data);
      } catch {
        notification.error({
          message: Errors.request_error_title,
          description: Errors.request_error_description,
        });
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return {
    navigate,
    loading,
    editCategoryModalVisible,
    setEditCategoryModalVisible,
    categories,
    selectedCategory,
    setSelectedCategory,
  };
};
