import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { LoginForm } from 'pages/Login/LoginForm';
import { NotFound } from 'pages/Login/NotFound';
import { ForgotPassword } from 'pages/Login/ForgotPassword/ForgotPassword';
import { NewPassword } from 'pages/Login/ForgotPassword/NewPassword';

function LoginRoutes(): JSX.Element {
  const location = useLocation();

  const redirectUrl = location.pathname.startsWith('/invites')
    ? `?redirect=${window.location.href}`
    : '';

  if (redirectUrl) {
    return <Navigate replace to={`${redirectUrl}`} />;
  }

  return (
    <Routes>
      <Route path="/" element={<LoginForm />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/recovery/:code/" element={<NewPassword />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export { LoginRoutes };
