import { MenuItem } from 'interfaces/menuItem';
import IconEstablishment from 'assets/svg/place.svg';
import IconUser from 'assets/svg/user.svg';
import IconCategory from 'assets/svg/category.svg';

const menuData: MenuItem[] = [
  {
    label: 'Colaboradores',
    route: '/employees',
    icon: <img alt="Ícone colaboradores" src={IconUser} />,
  },
  {
    label: 'Estabelecimento',
    route: '/establishment',
    icon: <img alt="Ícone estabelecimento" src={IconEstablishment} />,
  },
  {
    label: 'Categorias',
    route: '/category',
    icon: <img alt="Ícone categorias" src={IconCategory} />,
  },
];

export default menuData;
