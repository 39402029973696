import { notification } from 'antd';
import { ApiErrors, Errors } from 'constants/messages';
import { v4 as uuidv4 } from 'uuid';
import { Category } from 'interfaces/category';
import { useState } from 'react';
import { api } from 'services/api';
import { useNavigate } from 'react-router';

export const useCategoryForm = () => {
  const [loading, setLoading] = useState(false);
  const [addIconModalVisible, setAddIconModalVisible] = useState(false);
  const [categories, setCategories] = useState<Category[]>([
    { id: uuidv4() } as Category,
  ]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const navigate = useNavigate();

  function handleSubmit() {
    (async () => {
      try {
        setLoading(true);

        await Promise.all(
          categories.map(x =>
            api.post('/admin/product-categories', {
              name: x.name,
              categoryIconId: x.categoryIcon.id,
            }),
          ),
        );

        notification.success({
          message: 'Sucesso',
          description: 'Categorias salvas com sucesso.',
        });
        navigate('/category/category-management');
      } catch (err: any) {
        if (
          err.response?.data?.error?.code ===
          ApiErrors.HasEstablishmentProductCategoryConstraintError
        ) {
          notification.error({
            message: 'Erro!',
            description: 'Este ícone já está sendo utilizado',
          });
        } else {
          notification.error({
            message: Errors.request_error_title,
            description: Errors.request_error_description,
          });
        }
      } finally {
        setLoading(false);
      }
    })();
  }

  function handleOpenModal(index: number) {
    setSelectedIndex(index);
    setAddIconModalVisible(true);
  }

  function handleRemoveCategory(id: string) {
    setCategories(prevState => prevState.filter(x => x.id !== id));
  }

  return {
    loading,
    categories,
    addIconModalVisible,
    selectedIndex,
    setCategories,
    setAddIconModalVisible,
    handleSubmit,
    handleOpenModal,
    handleRemoveCategory,
  };
};
