import styled from 'styled-components';

interface ImageUploadProps {
  isDragActive: boolean;
  $loading: boolean | undefined;
}

export const Files = styled.div`
  height: calc(100vh * 0.7);
`;

export const ImageUpload = styled.div<ImageUploadProps>`
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  height: 264px;

  background: var(--shape);

  outline: 0;
  border-radius: 3px;

  border-width: 1px;
  border-style: dashed;

  border-color: ${({ isDragActive }) =>
    isDragActive ? 'var(--primary)' : 'var(--dropzone-border-color)'};

  &:hover {
    .cloud-container {
      background: var(--primary);
    }

    .anticon.anticon-cloud-upload {
      color: var(--shape);
    }
  }

  .cloud-container {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: var(--primary-background);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }

  h2 {
    color: var(--title);
  }

  span {
    color: var(--text);
  }

  span.highlight {
    color: var(--primary);
    font-weight: 600;
  }

  .anticon.anticon-cloud-upload {
    font-size: 50px;
    color: var(--primary);
  }
`;
