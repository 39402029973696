import { memo, useState, useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { debounce } from 'helpers/debounce';
import LogoImg from 'assets/svg/logo.svg';
import LogoSymbol from 'assets/images/logo_symbol.png';
import { MenuItem as IMenuItem } from 'interfaces/menuItem';
import { useEstablishment } from 'hooks/useEstablishment';
import {
  Container,
  Logo,
  Menu,
  MenuItem,
  ToggleButton,
  LeftOutlinedIcon,
  RightOutlinedIcon,
} from './styles';
import data from './data';

function MainDrawer() {
  const { currentEstablishment } = useEstablishment();
  const [open, setOpen] = useState(true);
  const [menuData, setMenuData] = useState<IMenuItem[]>([]);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const activePath = `/${pathname.split('/')[1]}`;

  useEffect(() => {
    const userRoles: string[] = [];

    if (userRoles.length) {
      setMenuData(
        data.filter(
          x =>
            x.roles &&
            x.roles.some(y => userRoles.indexOf(y.toLowerCase()) > -1),
        ),
      );
    } else {
      setMenuData(data.filter(x => !x.roles));
    }
  }, [currentEstablishment?.employees]);

  const toggleOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);

  useEffect(() => {
    // use debounce to prevent excessive calls
    const resizeListener = debounce(() => {
      // close or open drawer based on screen length
      setOpen(window.innerWidth > 768);
    }, 200);

    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  const handleMenuItem = useCallback(
    (route: string) => () => {
      navigate(route);
    },
    [navigate],
  );

  return (
    <Container className={open ? '' : 'closed'}>
      <div>
        <Logo
          src={open ? LogoImg : LogoSymbol}
          style={!open ? { width: 'inherit' } : { width: 140 }}
          className={open ? '' : 'closed'}
        />
        <ToggleButton onClick={toggleOpen} open={open}>
          {open ? <LeftOutlinedIcon /> : <RightOutlinedIcon />}
        </ToggleButton>
        <Menu
          selectedKeys={[activePath || '/dashboard']}
          className={open ? '' : 'closed'}
        >
          {menuData.map(item => (
            <MenuItem
              onClick={handleMenuItem(item.route)}
              key={item.route}
              icon={item.icon}
            >
              {item.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </Container>
  );
}

export default memo(MainDrawer);
