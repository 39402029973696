import styled from 'styled-components';
import { Modal as antdModal, Button as AntdButton } from 'antd';

export const Modal = styled(antdModal)`
  width: 528px !important;

  h1 {
    font-weight: 600;
    font-size: 23px;
    line-height: 28px;
    color: var(--title);
  }

  span.ant-modal-close-x {
    display: none;
  }

  div.icon-container {
    button {
      width: 60px;
      height: 60px;

      img {
        max-height: 40px;
        max-width: 40px;
        width: 80%;
      }
    }
  }
`;

export const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 35px 0 40px;
`;

export const SwitchContainer = styled.div`
  display: flex;

  span {
    font-size: 19px;
    line-height: 24px;
    color: var(--title);
    margin-left: 15px;
  }
`;

export const DeleteButton = styled.button`
  display: flex;

  img {
    height: 25px;
  }

  span {
    font-size: 19px;
    line-height: 24px;
    color: var(--error);
    margin-left: 15px;
  }
`;

export const Button = styled(AntdButton)`
  text-shadow: none;
  box-shadow: none;
  width: 100%;
  font-size: 19px;
`;

export const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CloseButton = styled.div`
  cursor: pointer;
`;
