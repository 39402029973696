import styled from 'styled-components';
import { Button as AntdButton } from 'antd';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px 0;

  button + button {
    margin-left: 20px;
  }

  button.ghost:hover {
    color: inherit;
    border-color: inherit;
  }
`;

export const Button = styled(AntdButton)`
  text-shadow: none;
  box-shadow: none;
`;
