import Cookies from 'universal-cookie';
import { getAuthToken, getKeepUser, clearStorage } from 'services/storage';

const cookies = new Cookies();

export function getToken(
  stateToken: string | null = null,
  isToClearStorage = false,
): string | null {
  const token = !stateToken ? getAuthToken() : stateToken;
  const keepUser = getKeepUser();

  if (token) {
    let cookieToken = cookies.get('token');

    if (!cookieToken) {
      if (keepUser === true) {
        cookieToken = token;
        cookies.set('token', token, {
          path: '/',
          sameSite: 'strict',
        });
        return token;
      }
      if (isToClearStorage) clearStorage();
      return null;
    }
    return token;
  }

  return null;
}
