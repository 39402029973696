import { useCallback } from 'react';
import DropzoneComponent, {
  DropzoneOptions,
  FileError,
  FileRejection,
} from 'react-dropzone';

import EditIcon from 'assets/svg/edit.svg';

import { notification } from 'antd';
import { ImageUpload } from './styles';

const acceptFiles = ['image/webp'];

interface IDropzoneProps extends DropzoneOptions {
  imagePreview?: string;
}

export default function Dropzone({
  onDropAccepted,
  imagePreview = '',
}: IDropzoneProps): JSX.Element {
  const onDropRejected = useCallback((rejectedFiles: FileRejection[]) => {
    const imgData = rejectedFiles;

    imgData.forEach(x =>
      x.errors.forEach((error: FileError) => {
        const errorMessage = {
          message: 'Erro',
          description: '',
        };

        if (error.code === 'file-too-large') {
          errorMessage.description = `O ícone ${x.file.name} possui tamanho além do máximo permitido (1mb)`;
        } else if (error.code === 'file-invalid-type') {
          errorMessage.description = `O ícone ${x.file.name} possui tipo diferente do permitido (.webp)`;
        }

        notification.error(errorMessage);
      }),
    );
  }, []);

  return (
    <DropzoneComponent
      onDropAccepted={onDropAccepted}
      onDropRejected={onDropRejected}
      accept={acceptFiles}
      maxSize={1000000}
      data-testid="dropzone"
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <ImageUpload {...getRootProps()} isDragActive={isDragActive}>
          <div className="edit-icon">
            <img src={EditIcon} alt="Editar" />
          </div>
          <img src={imagePreview} alt="Ícone" />
          <input {...getInputProps()} />
        </ImageUpload>
      )}
    </DropzoneComponent>
  );
}
