import Page from 'components/Page';

import AddIcon from 'assets/svg/icon-add.svg';

import { Col, Row } from 'antd';

import { gutter } from 'constants/styles';
import { Header, Button } from './styles';
import { IconCard } from './IconCard';
import EditIconModal from './EditIconModal';
import { tabRoutes } from '../tabRoutes';
import { useIconManagement } from './useIconManagement';

function IconManagement() {
  const {
    navigate,
    loading,
    editIconModalVisible,
    setEditIconModalVisible,
    categoryIcons,
    selectedIcon,
    setSelectedIcon,
  } = useIconManagement();

  return (
    <Page
      title="Categorias"
      tabs={tabRoutes}
      loading={loading}
      SubHeader={
        <Header>
          <Button
            onClick={() => {
              navigate('create');
            }}
            htmlType="button"
            type="primary"
          >
            <img src={AddIcon} alt="Adicionar ícone" />
            Adicionar ícone
          </Button>
        </Header>
      }
    >
      <Row gutter={gutter} className="mb-section">
        {categoryIcons.map(item => (
          <Col sm={24} lg={8} xl={6} key={item.id}>
            <IconCard
              categoryIcon={item}
              onEdit={() => {
                setSelectedIcon(item);
                setEditIconModalVisible(true);
              }}
            />
          </Col>
        ))}
      </Row>

      {!!selectedIcon && (
        <EditIconModal
          icon={selectedIcon}
          visible={editIconModalVisible}
          setVisible={setEditIconModalVisible}
        />
      )}
    </Page>
  );
}

export { IconManagement };
