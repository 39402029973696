import {
  Container,
  BackgroundCol,
  FormCol,
  Background,
  Logo,
  WelcomeContainer,
  WelcomeTitle,
  WelcomeSubTitle,
} from '../styles';

interface LoginLayoutProps {
  children: React.ReactNode;
}

function LoginLayout({ children }: LoginLayoutProps): JSX.Element {
  return (
    <Container>
      <BackgroundCol md={12} xs={24}>
        <Logo />
        <WelcomeContainer>
          <WelcomeTitle>
            Conectando pessoas <br />à experiências
          </WelcomeTitle>
          <WelcomeSubTitle>
            Uma ferramenta ideal cada etapa do seu <br />
            ecossistema de entretenimento.
          </WelcomeSubTitle>
        </WelcomeContainer>
        <Background />
      </BackgroundCol>
      <FormCol md={12} xs={24}>
        {children}
      </FormCol>
    </Container>
  );
}

export { LoginLayout };
