import styled from 'styled-components';
import EditImg from 'assets/svg/edit.svg';
import { ImagePlaceholder as IP } from 'components/ImagePlaceholder';

interface ImageProps {
  src: string;
}

export const Container = styled.div`
  background: var(--shape);
  padding: 1.875rem 7%;
  border: 1px solid var(--border-color);
  border-radius: 3px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  position: relative;

  &:hover {
    box-shadow: 0px 5px 20px -5px rgba(133, 133, 133, 0.15);
    border-color: var(--hovered-border-color);
  }
`;

export const StatusBadge = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--success);
  position: absolute;
  top: 8px;
  right: 8px;
`;

export const ImageContainer = styled.div`
  height: 40px;
  margin-right: 7%;
`;

export const Image = styled.img.attrs(({ src }: ImageProps) => ({
  src,
}))`
  height: 100%;
`;

export const ImagePlaceholder = styled(IP)`
  margin-right: 7%;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 20px;
  flex: 1;
`;

export const DescriptionTitle = styled.span`
  font-weight: bold;
  color: var(--title);
  font-size: 16px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 90%;
`;

export const DescriptionText = styled.span`
  color: var(--text);

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 90%;
`;

export const EditButton = styled.button`
  margin-left: auto;
`;

export const EditIcon = styled.img.attrs({
  src: EditImg,
})``;
