import { Row } from 'antd';
import Page from 'components/Page';
import { gutter } from 'constants/styles';
import { useNavigate } from 'react-router';
import { Column, Header, Button } from './styles';
import Dropzone from './Dropzone';
import ConfirmModal from '../ConfirmModal';
import { tabRoutes } from '../../tabRoutes';
import { useIconForm } from './useIconForm';

export interface IconType extends Blob, File {
  key?: string;
  preview?: string;
}

function IconForm() {
  const navigate = useNavigate();
  const {
    loading,
    confirmModalVisible,
    icons,
    setConfirmModalVisible,
    handleSubmit,
    handleRemoveIcon,
    handleImageDrop,
  } = useIconForm();

  return (
    <Page
      title="Categorias"
      tabs={tabRoutes}
      SubHeader={
        <Header>
          <Button
            onClick={() => {
              if (icons.length) setConfirmModalVisible(true);
              else navigate(-1);
            }}
            htmlType="button"
            type="default"
          >
            Cancelar
          </Button>

          <Button
            disabled={loading}
            onClick={handleSubmit}
            htmlType="submit"
            type="primary"
          >
            {loading ? 'Salvando' : 'Salvar'}
          </Button>
        </Header>
      }
    >
      {!icons.length && (
        <Row gutter={gutter} className="mb-section">
          <Column sm={24} lg={12}>
            <h1>Adicione novos ícones</h1>
            <p>
              Carregue os arquivos dos ícones, assim eles estarão disponíveis
              para serem usados pelos clientes do sistema FanPDV.
            </p>
          </Column>
        </Row>
      )}
      <Dropzone
        files={icons}
        onDropAccepted={handleImageDrop}
        onRemoveIcon={handleRemoveIcon}
      />

      <ConfirmModal
        visible={confirmModalVisible}
        setVisible={setConfirmModalVisible}
      />
    </Page>
  );
}

export { IconForm };
