import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  :root {
    --background: #FAFAFA;
    --primary: #FE6321;
    --primary-background: #ffefe9;
    --title: #000000;
    --dark: #333;
    --text: #67687A;
    --heading-color: #535353;
    --shape: #FFFFFF;
    --border-color: #ececec;
    --dropzone-border-color: #B8BBC2;
    --hovered-border-color: #e0e0e0;
    --disabled: #C4C4C4;
    --success: #68D105;
    --error: #FF0000;
    --font-size-base: 16px;
    --font-size-lg: 16px;
    --input-height-base: 60px;
    --input-height-lg: 60px;
    --input-height-sm: 40px;
    --border-radius-base: 8px;
    --border-width-base: 2px;
    --btn-border-radius-base: 10px;
    --input-padding-horizontal: 21px;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  html {
    @media (max-width: 1080px) {
      font-size: 93.75%;
    }

    @media (max-width: 720px) {
      font-size: 87.5%;
    }
  }

  body {
    background: var(--background);
    -webkit-font-smoothing: antialiased;
  }

  body, input, textarea, button {
    font-family: 'Titillium Web', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
     'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-weight: 400;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 600;
  }

  button {
    cursor: pointer;
    background: transparent;
    outline: 0;
    border:0;
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .ant-input, .ant-input-affix-wrapper, .react-tel-input .form-control  {
    border-radius: 3px;
    border: 1px solid var(--border-color);
  }

  .ant-input:hover {
    box-shadow: none;
    border-right-width: 1px;
  }

  .ant-table {
    background: transparent;
  }

  .ant-table-thead > tr > th {
    color: #000;
    background: transparent;
  }

  .ant-table-tbody > tr:nth-of-type(even) > td {
    background: #F8F8F8;
  }

  .ant-table-tbody > tr td:last-of-type {
    min-width: 85px;
  }

  .ant-pagination-options-size-changer {
      > div.ant-select-selector {
        height: 32px !important;
      }

      .ant-select-selection-item {
        line-height: 27px !important;
      }
    }
`;
