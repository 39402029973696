import { TranslateCategories, translateCategories } from 'constants/translate';
import { capitalize } from 'helpers/stringHelper';
import { Category } from 'interfaces/category';
import { useMemo } from 'react';

import {
  Container,
  StatusBadge,
  ImageContainer,
  ImagePlaceholder,
  Image,
  DescriptionContainer,
  DescriptionTitle,
  DescriptionText,
  EditButton,
  EditIcon,
} from './styles';

interface CategoryCardProps {
  category: Category;
  onEdit: () => void;
}

function CategoryCard({ category, onEdit }: CategoryCardProps) {
  const capitalizeCategoryName = useMemo(() => {
    const value =
      translateCategories[
        category.name
          .replace(' ', '_')
          .toLowerCase() as keyof TranslateCategories
      ] || category.name;
    return capitalize(value);
  }, [category]);

  return (
    <Container>
      {!category.disabledAt && <StatusBadge />}
      {category.categoryIcon ? (
        <ImageContainer>
          <Image src={category.categoryIcon.icon.location} />
        </ImageContainer>
      ) : (
        <ImagePlaceholder />
      )}
      <DescriptionContainer>
        <DescriptionTitle title={capitalizeCategoryName}>
          {capitalizeCategoryName}
        </DescriptionTitle>
        <DescriptionText title={category.name}>
          {category.name.toLowerCase()}
        </DescriptionText>
      </DescriptionContainer>
      <EditButton type="button" onClick={onEdit}>
        <EditIcon />
      </EditButton>
    </Container>
  );
}

export { CategoryCard };
