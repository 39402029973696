export const tabRoutes = [
  {
    label: 'Gestão de ícones',
    route: '/category/icon-management',
  },
  {
    label: 'Gestão de categorias',
    route: '/category/category-management',
  },
];
