import styled from 'styled-components';
import { Button as BTN, Col } from 'antd';

export const Column = styled(Col)`
  margin: 0 auto;

  h1 {
    margin-top: 90px;
    color: var(--title);
    font-size: 27px;
    line-height: 33px;
    font-weight: 700;
  }

  p {
    color: var(--text);
    line-height: 20px;
    margin-bottom: 50px;
  }

  label {
    color: var(--title);
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px 0;

  button + button {
    margin-left: 20px;
  }
`;

export const Button = styled(BTN)`
  text-shadow: none;
  box-shadow: none;
`;
