import { SubmitButton } from '../styles';
import { Header } from './Header';
import { Container, Title, Input, ErrorMessage } from './styles';
import { useForgotPassword } from './useForgotPassword';

function ForgotPassword(): JSX.Element {
  const { loading, input, errorMessage, setInput, handleSubmit } =
    useForgotPassword();

  return (
    <>
      <Header />
      <Container>
        <Title>Digite seu e-mail ou telefone</Title>

        <Input
          onChange={e => setInput(e.target.value)}
          value={input}
          placeholder="+5532999999999 / email@contato.com"
        />
        <ErrorMessage>{errorMessage}</ErrorMessage>

        <SubmitButton
          disabled={loading}
          htmlType="submit"
          type="primary"
          size="large"
          style={{ width: 160 }}
          onClick={() => handleSubmit()}
        >
          {loading ? 'Enviando' : 'Confirmar'}
        </SubmitButton>
      </Container>
    </>
  );
}

export { ForgotPassword };
