import styled from 'styled-components';
import { Button as btn } from 'antd';

export const Container = styled.div`
  width: fit-content;
  text-align: center;
  margin: 0 auto;
  margin-left: 10%;

  h1 {
    margin-top: 1.25rem;
  }
`;

export const Button = styled(btn)`
  width: 130px;
  height: 47px;
  margin: 0.625rem auto;
  margin-bottom: 1.875rem;

  &.ant-btn-primary {
    background-color: var(--title);
    border-color: var(--title);
  }

  &:hover {
    background-color: var(--dark);
    border-color: var(--dark);
  }
`;
