export type TranslateIcons = {
  [key: string]: string;
};

export type TranslateCategories = {
  [key: string]: string;
};

export const translateIcons: TranslateIcons = {
  beer: 'cerveja',
  bubble_gum: 'chiclete',
  burguer: 'hambúrguer',
  caipirinha: 'caipirinha',
  candy: 'doce',
  champagne: 'champanhe',
  cocktail: 'coquetel',
  combo: 'combo',
  dessert: 'sobremesa',
  distilled: 'destilado',
  drink: 'bebida',
  energetic: 'energético',
  finger_food: 'petisco',
  food: 'comida',
  gin: 'gin',
  juice: 'suco',
  liquor: 'licor',
  main_dish: 'prato principal',
  martini: 'martini',
  meat: 'carne',
  other: 'outros',
  pasta: 'massa',
  pizza: 'pizza',
  popsicle: 'picolé',
  sea_food: 'frutos do mar',
  soda: 'soda',
  starter: 'entrada',
  sweet: 'doce',
  vermouth: 'vermute',
  vodka: 'vodka',
  water: 'agua',
  whisky: 'uísque',
  wine: 'vinho',
  cigarette: 'cigarro',
  snack: 'lanche',
};

export const translateCategories: TranslateCategories = {
  absinthe: 'Absinto',
  cereal_bar: 'Barra de cereal',
  savory_snacks: 'Salgadinho',
  caipirinha: 'Caipirinha',
  campari: 'Campari',
  martini: 'Martini',
  rum: 'Rum',
  vodka: 'Vodka',
  tequila: 'Tequila',
  whisky: 'Uísque',
  cereal: 'Cereal',
  burguer: 'Hambúrguer',
  pizza: 'Pizza',
  salgado: 'Salgado',
  barra: 'Barra de cereal',
  candy: 'Doce',
  cocktail: 'Coquetel',
  dairy_beverage: 'Bebida láctea',
  cookie: 'Biscoito',
  cake: 'Bolo',
  hot_dog: 'Cachorro-quente',
  broth: 'Caldo',
  coffe: 'Café',
  meat: 'Carne',
  beer: 'Cerveja',
  champagne: 'Champanhe',
  bubble_gum: 'Chiclete',
  cigarrette: 'Cigarro',
  brandy: 'Conhaque',
  distilled: 'Destilado',
  energetic: 'Energético',
  sparkling_wine: 'Vinho espumante',
  starter: 'Entrada',
  sweet: 'Doce',
  snacks: 'Lanches',
  sea_food: 'Frutos do mar',
  gin: 'Gin',
  fruit: 'Fruta',
  liquor: 'Licor',
  pasta: 'Massa',
  grilled: 'Grelhado',
  other: 'Outros',
  fried_pastry: 'Pastel',
  finger_food: 'Petisco',
  popsicle: 'Picolé',
  main_dish: 'Prato principal',
  soda: 'Soda',
  dessert: 'Sobremesa',
  sandwich: 'Sanduíche',
  juice: 'Suco',
  pie: 'Torta',
  vermouth: 'Vermute',
  smoothie: 'Smoothie',
  wine: 'Vinho',
  water: 'Água',
};
