import axios from 'axios';
import { API_ENDPOINT } from 'constants/environment';
import { getToken } from './token';
import { clearStorage } from './storage';

const api = axios.create({
  baseURL: API_ENDPOINT,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  async config => {
    try {
      const token = getToken(null, true);

      if (token) config.headers!.Authorization = `Bearer ${token}`;
      else config.headers!.Authorization = '';

      return {
        ...config,
      };
    } catch (error) {
      return config;
    }
  },
  error => Promise.reject(error),
);

api.interceptors.response.use(
  response => response,
  error => {
    const token = getToken(null, true);
    if (error?.response?.status === 401 && token) {
      clearStorage();
      window.location.href = '/';
    }

    return Promise.reject(error);
  },
);

export { api };
