import 'react-phone-input-2/lib/plain.css';
import './App.less';
import { GlobalStyle } from 'styles/global';
import { BrowserRouter } from 'react-router-dom';
import { Routes } from 'routes';
import { AppProvider } from 'hooks';

function App() {
  return (
    <BrowserRouter>
      <AppProvider>
        <GlobalStyle />
        <Routes />
      </AppProvider>
    </BrowserRouter>
  );
}

export default App;
