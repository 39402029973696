import styled from 'styled-components';

interface ImageUploadProps {
  isDragActive: boolean;
}

export const ImageUpload = styled.div<ImageUploadProps>`
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;

  width: 100%;
  height: 180px;

  background: var(--shape);

  outline: 0;
  border-radius: 3px;

  border-width: 1px;
  border-style: solid;

  border-color: ${({ isDragActive }) =>
    isDragActive ? 'var(--primary)' : 'var(--border-color)'};

  div.edit-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      position: relative;
      top: -1px;
      width: 25px;
      filter: brightness(100);
    }
  }

  img {
    height: 60%;
  }
`;
