import styled from 'styled-components';
import { Button as BTN, Col } from 'antd';

export const Column = styled(Col)`
  margin: 0 auto;

  h1 {
    margin-top: 90px;
    color: var(--title);
    font-size: 27px;
    line-height: 33px;
    font-weight: 700;
  }

  p {
    color: var(--text);
    line-height: 20px;
    margin-bottom: 50px;
  }

  label {
    color: var(--title);
  }
`;

export const AddIconButton = styled(BTN)`
  display: block;
  padding: 0;
  margin-bottom: 30px;

  span.plus-icon {
    position: relative;
    top: -2px;
    margin-right: 10px;
    background: var(--primary);
    color: var(--shape);
    border-radius: 50%;
    width: 15px;
    height: 15px;
    line-height: 11px;
  }
`;

export const PlaceholderIcon = styled.button`
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background: #f1f1f3;
  outline: 0;
  border: 0;
  margin-bottom: 15px;

  img {
    max-height: 50px;
    max-width: 50px;
    width: 80%;
  }
`;

export const Separator = styled.div`
  width: 100%;
  border: 1px solid var(--border-color);
  margin: 50px 0 40px;
`;

export const AddMoreButton = styled.button`
  border: 2px dashed var(--success);
  border-radius: 3px;
  height: 50px;
  color: var(--success);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  margin-top: 50px;

  &:hover {
    background-color: var(--border-color);
  }

  div.plus-icon {
    display: flex;
    position: relative;
    top: -1px;
    margin-right: 10px;
    background: var(--success);
    color: var(--shape);
    border-radius: 50%;
    width: 17px;
    height: 17px;
    line-height: 11px;
    font-weight: bold;
    padding-left: 4px;
    padding-top: 2px;
  }
`;

export const FormRow = styled.div`
  position: relative;

  .remove-button {
    position: absolute;
    top: 52px;
    right: 8px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px 0;

  button + button {
    margin-left: 20px;
  }
`;

export const Button = styled(BTN)`
  text-shadow: none;
  box-shadow: none;
`;
