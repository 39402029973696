import Page from 'components/Page';

import AddIcon from 'assets/svg/icon-add.svg';

import { Col, Row } from 'antd';

import { gutter } from 'constants/styles';
import { Header, Button } from './styles';
import { CategoryCard } from './CategoryCard';
import EditCategoryModal from './EditCategoryModal';
import { tabRoutes } from '../tabRoutes';
import { useCategoryManagement } from './useCategoryManagement';

function CategoryManagement() {
  const {
    navigate,
    loading,
    editCategoryModalVisible,
    setEditCategoryModalVisible,
    categories,
    selectedCategory,
    setSelectedCategory,
  } = useCategoryManagement();

  return (
    <Page
      title="Categorias"
      tabs={tabRoutes}
      loading={loading}
      SubHeader={
        <Header>
          <Button
            onClick={() => {
              navigate('create');
            }}
            htmlType="button"
            type="primary"
          >
            <img src={AddIcon} alt="Adicionar ícone" />
            Adicionar categoria
          </Button>
        </Header>
      }
    >
      <Row gutter={gutter} className="mb-section">
        {categories.map(item => (
          <Col sm={24} lg={8} xl={6} key={item.id}>
            <CategoryCard
              category={item}
              onEdit={() => {
                setSelectedCategory(item);
                setEditCategoryModalVisible(true);
              }}
            />
          </Col>
        ))}
      </Row>

      {!!selectedCategory && (
        <EditCategoryModal
          category={selectedCategory}
          visible={editCategoryModalVisible}
          setVisible={setEditCategoryModalVisible}
        />
      )}
    </Page>
  );
}

export { CategoryManagement };
