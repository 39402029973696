import { useCallback } from 'react';
import DropzoneComponent, {
  DropzoneOptions,
  FileError,
  FileRejection,
} from 'react-dropzone';
import { CloudUploadOutlined } from '@ant-design/icons';

import { Col, notification, Row } from 'antd';
import { gutter } from 'constants/styles';
import { Files, ImageUpload } from './styles';
import { Column } from '../styles';
import { FileCard } from './FileCard';
import { IconType } from '..';

const acceptFiles = ['image/webp'];

interface IDropzoneProps extends DropzoneOptions {
  loading?: boolean;
  files: IconType[];
  onRemoveIcon: (key: string) => void;
}

export default function Dropzone({
  onDropAccepted,
  onRemoveIcon,
  files,
  loading = false,
}: IDropzoneProps): JSX.Element {
  const onDropRejected = useCallback((rejectedFiles: FileRejection[]) => {
    const imgData = rejectedFiles;

    imgData.forEach(x =>
      x.errors.forEach((error: FileError) => {
        const errorMessage = {
          message: 'Erro',
          description: '',
        };

        if (error.code === 'file-too-large') {
          errorMessage.description = `O ícone ${x.file.name} possui tamanho além do máximo permitido (1mb)`;
        } else if (error.code === 'file-invalid-type') {
          errorMessage.description = `O ícone ${x.file.name} possui tipo diferente do permitido (.webp)`;
        }

        notification.error(errorMessage);
      }),
    );
  }, []);

  return (
    <DropzoneComponent
      onDropAccepted={onDropAccepted}
      onDropRejected={onDropRejected}
      accept={acceptFiles}
      maxSize={1000000}
      multiple
      data-testid="dropzone"
      maxFiles={30}
      noClick={!!files.length}
    >
      {({ getRootProps, getInputProps, isDragActive }) =>
        files.length ? (
          <Files
            gutter={gutter}
            className="mb-section"
            {...getRootProps()}
            multiple
          >
            <Row gutter={gutter} className="mb-section">
              {files.map(item => (
                <Col sm={24} lg={8} xl={6} key={item.key}>
                  <FileCard onRemoveIcon={onRemoveIcon} file={item} />
                </Col>
              ))}
            </Row>
          </Files>
        ) : (
          <Row gutter={gutter} className="mb-section">
            <Column sm={24} lg={12} {...getRootProps()} multiple>
              <ImageUpload $loading={loading} isDragActive={isDragActive}>
                <div className="cloud-container">
                  <CloudUploadOutlined />
                </div>
                <h2>Arraste seus arquivos ou clique para adicionar</h2>
                <span>
                  O arquivo deve estar nas proporções de{' '}
                  <span className="highlight">500x500 px</span> e com o máximo
                  de <span className="highlight">50 kb</span> cada
                </span>
                <input {...getInputProps()} />
              </ImageUpload>
            </Column>
          </Row>
        )
      }
    </DropzoneComponent>
  );
}
