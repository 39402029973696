import axios from 'axios';
import { FANAPP_API_ENDPOINT, SOUFAN_VERSION_KEY } from 'constants/environment';
import { getToken } from './token';

const fanappApi = axios.create({
  baseURL: FANAPP_API_ENDPOINT,
  timeout: 5000,
});

fanappApi.defaults.headers.common['X-SOUFAN-VERSION-KEY'] = SOUFAN_VERSION_KEY!;

fanappApi.interceptors.request.use(
  async config => {
    try {
      const token = getToken(null, true);

      if (token) config.headers!.Authorization = `Bearer ${token}`;
      else config.headers!.Authorization = '';

      return {
        ...config,
      };
    } catch (error) {
      return config;
    }
  },
  error => Promise.reject(error),
);

export { fanappApi };
