import styled from 'styled-components';
import LogoImg from 'assets/svg/color-logo.svg';
import { Input as AntdInput } from 'antd';

export const HeaderContainer = styled.header`
  max-width: 80%;
  width: 100%;
  margin: 20px auto;
  padding: 10px 20px;

  display: flex;
  justify-content: space-between;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 50%;
  margin: 15% auto;

  .ant-input:hover,
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper:hover,
  .ant-input-affix-wrapper-focused,
  .ant-input:focus,
  .ant-input-focused,
  .ant-input:hover,
  .ant-input:focus,
  .ant-input-focused {
    border-color: #a735d1 !important;
    box-shadow: none !important;
    border-right-width: 0px;
    border-width: 2px !important;
  }
`;

export const Logo = styled.img.attrs({
  src: LogoImg,
})``;

export const Title = styled.h1`
  color: var(--title);
  font-size: 1.875rem;
  font-weight: 700;
`;

export const Input = styled(AntdInput)`
  color: var(--dark);
  margin: 30px 0 0;
  border-radius: 0;
  background: transparent;
  border: 2px solid var(--dropzone-border-color) !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  height: 45px;
`;

export const InputPassword = styled(AntdInput.Password)`
  input {
    color: var(--dark);
    background: transparent;
  }
  margin: 30px 0 0;
  background: transparent;
  border-radius: 0 !important;
  border: 2px solid var(--dropzone-border-color) !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  height: 45px;
`;

export const ErrorMessage = styled.span`
  color: var(--error);
  display: block;
  margin-bottom: 30px;
  margin-left: 5px;
`;
