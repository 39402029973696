import { memo } from 'react';
import SearchImage from 'assets/svg/search.svg';
import { CategoryIcon } from 'interfaces/categoryIcon';
import { Modal, SearchContainer } from './styles';
import { useAddIconModal } from './useAddIconModal';

interface ModalProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  setIcon: (icon: CategoryIcon) => void;
}

function AddIconModal({ setIcon, visible, setVisible }: ModalProps) {
  const { setSearch, data } = useAddIconModal();

  return (
    <Modal
      title=""
      footer={false}
      visible={visible}
      destroyOnClose
      closable
      onCancel={() => {
        setVisible(false);
      }}
    >
      <SearchContainer>
        <img src={SearchImage} alt="Search" />
        <input
          type="text"
          placeholder="Pesquisar..."
          onChange={({ target }) => setSearch(target.value)}
        />
      </SearchContainer>

      <div className="icon-container">
        {data.map(x => (
          <button
            key={x.id}
            className="btn-clean"
            type="button"
            onClick={() => {
              setIcon(x);
              setVisible(false);
            }}
          >
            <img src={x.icon.location} alt={x.name} />
          </button>
        ))}
      </div>
    </Modal>
  );
}

export default memo(AddIconModal);
