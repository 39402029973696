import NotFoundImage from 'assets/svg/404.svg';

import { useNavigate } from 'react-router-dom';
import { LoginLayout } from '../LoginLayout';
import { Container, Button } from './styles';

function NotFound(): JSX.Element {
  const navigate = useNavigate();

  return (
    <LoginLayout>
      <Container>
        <img src={NotFoundImage} alt="404" />
        <h1>Página não encontrada</h1>
        <p>O endereço solicitado não existe ou não está mais disponível</p>

        <Button onClick={() => navigate('/')} type="primary">
          Login
        </Button>
      </Container>
    </LoginLayout>
  );
}

export { NotFound };
