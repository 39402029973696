import Cookies from 'universal-cookie';
import { StorageKeys } from 'constants/storage';
import { User } from 'interfaces/user';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { api } from 'services/api';
import { fanappApi } from 'services/fanappApi';
import {
  clearStorage,
  storeAuthToken,
  storeKeepUser,
  storeUser,
} from 'services/storage';
import { showError } from 'services/error';
import { useEstablishment } from './useEstablishment';

const cookies = new Cookies();

export interface LoginInput {
  phone?: string;
  email?: string;
  password: string;
}

interface AuthContextData {
  loggedUser: User | undefined;
  token: string | undefined;
  login: (
    input: LoginInput,
    persistToken: boolean,
    successCallback?: ((user: User) => void) | undefined,
  ) => Promise<void>;
  logout: () => Promise<void>;
  addLoggedUser: (user: User) => void;
  clearLoggedUser: () => void;
}

interface IAuthProviderProps {
  children: JSX.Element | JSX.Element[];
}

const AuthContext = createContext({} as AuthContextData);

function AuthProvider({ children }: IAuthProviderProps): JSX.Element {
  const { clearCurrentEstablishment } = useEstablishment();

  const [loggedUser, setLoggedUser] = useState<User | undefined>(() => {
    const serializedUser = localStorage.getItem(StorageKeys.USER);
    if (!serializedUser) return null;
    return JSON.parse(serializedUser);
  });

  const [token, setToken] = useState<string | undefined>(() => {
    const serializedToken = localStorage.getItem(StorageKeys.USER);
    if (!serializedToken) return null;
    return JSON.parse(serializedToken);
  });

  const login = useCallback(
    async (
      input: LoginInput,
      persistToken: boolean,
      successCallback?: (user: User) => void,
    ): Promise<void> => {
      try {
        const {
          data: {
            data: { token: apiToken, user: apiUser },
          },
        } = await api.post('login', input);

        setToken(apiToken);
        setLoggedUser(apiUser);
        storeAuthToken(apiToken);
        storeKeepUser(persistToken);
        storeUser(apiUser);

        cookies.set('token', apiToken, {
          sameSite: 'strict',
        });

        api.defaults.headers.common.Authorization = `Bearer ${apiToken}`;
        fanappApi.defaults.headers.common.Authorization = `Bearer ${apiToken}`;

        clearCurrentEstablishment();

        if (successCallback) successCallback(apiUser);
      } catch (error: any) {
        if (error.response?.status === 400 || error.response?.status === 401) {
          showError('Email e/ou senha está incorreto');
        } else {
          showError('Ocorreu um erro com a requisição. Tente novamente.');
        }
      }
    },
    [clearCurrentEstablishment],
  );

  const logout = useCallback(async () => {
    clearStorage();
    setLoggedUser(undefined);
    setToken(undefined);
    clearCurrentEstablishment();
  }, [clearCurrentEstablishment]);

  const addLoggedUser = useCallback((user: User) => {
    setLoggedUser(user);
    storeUser(user);
  }, []);

  const clearLoggedUser = useCallback(() => {
    setLoggedUser({} as User);
  }, []);

  const mountValue: AuthContextData = useMemo(
    () => ({
      loggedUser,
      token,
      login,
      logout,
      addLoggedUser,
      clearLoggedUser,
    }),
    [loggedUser, token, login, logout, addLoggedUser, clearLoggedUser],
  );

  return (
    <AuthContext.Provider value={mountValue}>{children}</AuthContext.Provider>
  );
}

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
