import styled from 'styled-components';
import { Col, Row, Typography, Button } from 'antd';
import background from 'assets/images/background_login.png';
import logo from 'assets/images/min_logo_light.png';

export const Container = styled(Row)`
  min-height: 100vh;
  height: 100%;
  overflow: hidden;

  ::selection {
    background: #a735d1 !important;
  }
`;

export const HeaderContainer = styled.header`
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 3rem;
`;

export const BackgroundCol = styled(Col)`
  position: relative;
`;

export const Background = styled.img.attrs({
  src: background,
  alt: 'fan background concert',
})`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const HighlightText = styled(Typography.Text)`
  color: var(--primary);
`;

export const Logo = styled.img.attrs({
  alt: 'logo fan pvp',
  src: logo,
})`
  object-fit: contain;
  width: 30px;
  z-index: 3;
  position: absolute;
  left: 30px;
  top: 30px;
`;

export const FormCol = styled(Col)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* @media (max-width: 768px) {
    padding-left: 2rem;
  } */
`;

export const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 0;
  font-weight: 300;
  line-height: 2.4rem;
  white-space: pre-line;
  color: var(--title);
`;

export const FormTitle = styled.h2`
  font-size: 1.875rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
`;

export const FormContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
  width: 100%;

  div::selection,
  a::selection,
  label::selection,
  p::selection,
  span::selection {
    background: #a735d1;
  }

  .custom-label {
    color: var(--title);
  }

  .ant-tabs-nav {
    margin-bottom: 50px;
  }

  .ant-tabs-ink-bar,
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #a735d1;
  }

  a:hover,
  a:active,
  .ant-tabs-tab-btn:focus,
  .ant-tabs-tab-remove:focus,
  .ant-tabs-tab-btn:active,
  .ant-tabs-tab-remove:active {
    color: #a735d1;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: #a735d1;
  }

  .ant-input:hover,
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused,
  .ant-input:focus,
  .ant-input-focused {
    border-color: #a735d1;
    box-shadow: none !important;
    border-right-width: 1px;
    border-width: 1px !important;
  }

  form {
    padding-bottom: 20px;
  }
`;

export const SubmitButton = styled(Button)`
  width: 100%;
  height: 60px;

  &.ant-btn-primary {
    background-color: var(--title);
    border-color: var(--title);
  }

  &:hover {
    background-color: var(--dark);
    border-color: var(--dark);
  }
`;

export const WelcomeContainer = styled.div`
  position: absolute;
  top: 200px;
  width: 70%;
  left: 15%;
  color: var(--shape);
`;

export const WelcomeTitle = styled.span`
  display: block;
  font-size: 3.5rem;
  line-height: 70px;
  font-weight: 600;
  margin-bottom: 30px;
`;

export const WelcomeSubTitle = styled.span`
  font-size: 18px;
`;
