import { notification } from 'antd';
import { Errors } from 'constants/messages';
import { v4 as uuidv4 } from 'uuid';
import { useCallback, useState } from 'react';
import { api } from 'services/api';
import { useNavigate } from 'react-router';
import { IconType } from '.';

export const useIconForm = () => {
  const [loading, setLoading] = useState(false);
  const [icons, setIcons] = useState<IconType[]>([]);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const navigate = useNavigate();

  function handleSubmit() {
    (async () => {
      try {
        setLoading(true);

        const formData = new FormData();
        icons.forEach(x => formData.append('categoryIcons', x));

        await api.post('/admin/category-icons', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        notification.success({
          message: 'Sucesso',
          description: 'Ícones salvos com sucesso.',
        });
        navigate('/category/icon-management');
      } catch {
        notification.error({
          message: Errors.request_error_title,
          description: Errors.request_error_description,
        });
      } finally {
        setLoading(false);
      }
    })();
  }

  const handleRemoveIcon = useCallback(
    (id: string) => {
      setIcons(icons.filter(x => x.key !== id));
    },
    [icons],
  );

  function readAndMount(file: IconType) {
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      () => {
        const formattedFile = file;
        formattedFile.preview = reader?.result?.toString();
        formattedFile.key = uuidv4();
        setIcons(prevState => [...prevState, formattedFile]);
      },
      false,
    );

    reader.readAsDataURL(file);
  }

  const handleImageDrop = useCallback(
    acceptedFiles => {
      const imgData = acceptedFiles;

      if (icons.length + imgData.length > 30) {
        notification.error({
          message: 'Erro',
          description: 'Selecione no máximo 30 itens',
        });

        return;
      }

      imgData.forEach((x: IconType) => {
        if (icons.find(y => y.name === x.name)) {
          notification.error({
            message: 'Erro',
            description: `Já foi adicionado um ícone com o mesmo nome (${x.name})`,
          });
        } else {
          readAndMount(x);
        }
      });
    },
    [icons],
  );

  return {
    loading,
    confirmModalVisible,
    icons,
    setConfirmModalVisible,
    handleSubmit,
    handleRemoveIcon,
    handleImageDrop,
  };
};
