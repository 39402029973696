import { memo } from 'react';
import { Input, Switch } from 'antd';
import TrashRedIcon from 'assets/svg/trash-red.svg';
import CloseIcon from 'assets/svg/close-icon.svg';

import { CategoryIcon } from 'interfaces/categoryIcon';
import {
  Modal,
  ControlsContainer,
  SwitchContainer,
  DeleteButton,
  Button,
  CloseContainer,
  CloseButton,
} from './styles';
import Dropzone from './Dropzone';
import { useEditIconModal } from './useEditIconModal';

interface ModalProps {
  icon: CategoryIcon;
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

function EditIconModal({ icon, visible, setVisible }: ModalProps) {
  const {
    loading,
    disabled,
    imagePreview,
    data,
    setData,
    setDisabled,
    handleSubmit,
    handleDelete,
    handleImageDrop,
    formatName,
  } = useEditIconModal(icon, visible, setVisible);

  return (
    <Modal
      title=""
      footer={false}
      visible={visible}
      destroyOnClose
      closable
      onCancel={() => {
        setVisible(false);
      }}
    >
      <CloseContainer>
        <CloseButton onClick={() => setVisible(false)}>
          <img src={CloseIcon} alt="Fechar" />
        </CloseButton>
      </CloseContainer>
      <h1>Editar ícone</h1>

      <Dropzone imagePreview={imagePreview} onDropAccepted={handleImageDrop} />

      <label>Título</label>

      <Input
        disabled
        value={formatName()}
        onChange={e => {
          setData({ ...data, name: e.target.value });
        }}
      />

      <ControlsContainer>
        <SwitchContainer>
          <Switch
            checked={!disabled}
            onChange={() => {
              setDisabled(!disabled);
            }}
          />
          <span>Habilitar para uso</span>
        </SwitchContainer>
        <DeleteButton onClick={handleDelete} disabled={loading}>
          <img src={TrashRedIcon} alt="Remover" className="trash-icon" />
          <span>Deletar ícone</span>
        </DeleteButton>
      </ControlsContainer>

      <Button
        onClick={handleSubmit}
        htmlType="button"
        type="primary"
        disabled={loading}
      >
        {loading ? 'Salvando' : 'Concluir'}
      </Button>
    </Modal>
  );
}

export default memo(EditIconModal);
