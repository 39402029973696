import { notification } from 'antd';
import { Errors } from 'constants/messages';
import { isPhoneNumber, isValidEmail } from 'helpers/stringHelper';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { api } from 'services/api';

interface Payload {
  phone?: string;
  email?: string;
}

export const useForgotPassword = () => {
  const navigate = useNavigate();
  const [loading] = useState(false);
  const [input, setInput] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  async function handleSubmit() {
    setErrorMessage('');
    const payload: Payload = {
      phone: `+${input}`,
      email: input,
    };
    let errorCounter = 0;

    if (!isPhoneNumber(input)) {
      errorCounter++;
      delete payload.phone;
    } else if (!isValidEmail(input)) {
      errorCounter++;
    }

    if (errorCounter === 2) {
      setErrorMessage('Por favor insira um email ou telefone válido');
    } else {
      try {
        await api.post('reset-password', {
          ...payload,
        });

        notification.success({
          message: 'Sucesso',
          description:
            'Um link para a recuperação de senha foi enviado para o seu email.',
        });

        navigate('/');
      } catch (err: any) {
        notification.error({
          message: Errors.request_error_title,
          description: Errors.request_error_description,
        });
      }
    }
  }

  return {
    loading,
    input,
    setInput,
    errorMessage,
    handleSubmit,
  };
};
