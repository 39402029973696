import { notification } from 'antd';
import { ApiErrors, Errors } from 'constants/messages';
import { Category } from 'interfaces/category';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { api } from 'services/api';

export const useEditCategoryModal = (
  category: Category,
  setVisible: (visible: boolean) => void,
) => {
  const [addIconModalVisible, setAddIconModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(!!category.disabledAt);
  const [categoryIcon, setCategoryIcon] = useState(category.categoryIcon);
  const [name, setName] = useState(category.name);
  const navigate = useNavigate();

  async function handleSubmit() {
    try {
      setLoading(true);

      if (disabled !== !!category.disabledAt) {
        await api.post(
          `/admin/product-categories/${category.id}/${
            disabled ? 'disable' : 'enable'
          }`,
        );
      }

      if (
        category.categoryIcon.id !== categoryIcon.id ||
        category.name !== name
      ) {
        await api.put(`/admin/product-categories/${category.id}`, {
          name,
          categoryIconId: categoryIcon.id,
        });

        notification.success({
          message: 'Sucesso',
          description: 'Alterações realizadas com sucesso.',
        });
        navigate(0);
      } else {
        setVisible(false);
      }
    } catch {
      notification.error({
        message: Errors.request_error_title,
        description: Errors.request_error_description,
      });
    } finally {
      setLoading(false);
    }
  }

  async function handleDelete() {
    try {
      setLoading(true);

      await api.delete(`/admin/product-categories/${category.id}`);

      notification.success({
        message: 'Sucesso',
        description: 'Categoria deletada com sucesso.',
      });
      navigate(0);
    } catch (err: any) {
      if (
        err.response?.data?.error?.code ===
        ApiErrors.HasEstablishmentProductCategoryConstraintError
      ) {
        notification.error({
          message: 'Erro!',
          description:
            'A categoria não pôde ser deletada pois está sendo utilizada',
        });
      } else if (
        err.response?.data?.error?.code ===
        ApiErrors.HasCategoryIconConstraintError
      ) {
        notification.error({
          message: 'Erro!',
          description:
            'O ícone selecionado está sendo utilizado por outra categoria',
        });
      } else {
        notification.error({
          message: Errors.request_error_title,
          description: Errors.request_error_description,
        });
      }
    } finally {
      setLoading(false);
    }
  }

  return {
    addIconModalVisible,
    loading,
    disabled,
    name,
    setName,
    setCategoryIcon,
    setAddIconModalVisible,
    setDisabled,
    handleSubmit,
    handleDelete,
  };
};
