import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { IconManagement } from 'pages/Authenticated/Category/IconManagement';
import { IconForm } from 'pages/Authenticated/Category/IconManagement/IconForm';
import { CategoryManagement } from 'pages/Authenticated/Category/CategoryManagement';
import { CategoryForm } from 'pages/Authenticated/Category/CategoryManagement/CategoryForm';
import { EstablishmentList } from 'pages/Authenticated/Establishment/List';

function AuthRoutes(): JSX.Element {
  const location = useLocation();

  const redirectUrl = location.pathname.startsWith('/invites')
    ? `?redirect=${window.location.href}`
    : '';

  if (redirectUrl) {
    return <Navigate replace to={`${redirectUrl}`} />;
  }

  return (
    <Routes>
      {/* Icon management */}
      <Route path="/category/icon-management" element={<IconManagement />} />
      <Route path="/category/icon-management/create" element={<IconForm />} />

      {/* Category management */}
      <Route
        path="/category/category-management"
        element={<CategoryManagement />}
      />
      <Route
        path="/category/category-management/create"
        element={<CategoryForm />}
      />

      <Route path="/establishment" element={<EstablishmentList />} />

      {/* General routes */}
      <Route
        path="/"
        element={<Navigate to="/category/icon-management" replace />}
      />
      <Route path="*" element={<IconManagement />} />
    </Routes>
  );
}

export { AuthRoutes };
