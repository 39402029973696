import { User } from 'interfaces/user';
import { createContext, useCallback, useContext, useMemo } from 'react';
import { api } from 'services/api';
import { getUser, storeUser } from 'services/storage';
import { showError } from 'services/error';
import { API_ENDPOINT } from 'constants/environment';
import { useAuth } from './useAuth';

interface UserContextData {
  passwordRecovery: (
    input: RecoveryInput,
    successCallback?: (() => void) | undefined,
  ) => Promise<void>;
  confirmRecovery: (
    code: string,
    successCallback?: (() => void) | undefined,
    errorCallback?: (() => void) | undefined,
  ) => Promise<void>;
  completeRecovery: (
    input: CompleteSignupInput,
    successCallback?: (() => void) | undefined,
  ) => Promise<void>;
  uploadAvatar: (
    imageData: File,
    successCallback?: ((user: User) => void) | undefined,
  ) => Promise<void>;
  successCallback?: (
    successCallback?: ((user: User) => void) | undefined,
  ) => Promise<void>;
}

export interface RecoveryInput {
  phone?: string;
  email?: string;
  redirect?: string | null;
}

export interface CompleteSignupInput {
  name: string;
  code: string;
  document: string;
  password: string;
}

interface UpdateUserData {
  name: string;
  document?: string;
}

interface IUserProviderProps {
  children: JSX.Element | JSX.Element[];
}

const UserContext = createContext({} as UserContextData);

function UserProvider({ children }: IUserProviderProps): JSX.Element {
  const { addLoggedUser } = useAuth();
  const passwordRecovery = useCallback(
    async (input: RecoveryInput, successCallback?: () => void) => {
      try {
        await api.post('reset-password', {
          ...input,
          redirect: input.redirect || `${API_ENDPOINT}/dashboard`,
        });

        if (successCallback) successCallback();
      } catch (error) {
        showError('Ocorreu um erro com a requisição. Tente novamente.');
      }
    },
    [],
  );

  const confirmRecovery = useCallback(
    async (
      code: string,
      successCallback?: () => void,
      errorCallback?: () => void,
    ) => {
      try {
        await api.post('reset-password/verify-code', { code });

        if (successCallback) successCallback();
      } catch (error) {
        showError('Ocorreu um erro com a requisição. Tente novamente.');
        if (errorCallback) errorCallback();
      }
    },
    [],
  );

  const completeRecovery = useCallback(
    async (input: CompleteSignupInput, successCallback?: () => void) => {
      try {
        await api.post('change-password', {
          ...input,
          code: input.code.toUpperCase(),
        });

        if (successCallback) successCallback();
      } catch (error) {
        showError('Ocorreu um erro com a requisição. Tente novamente.');
      }
    },
    [],
  );

  const uploadAvatar = useCallback(
    async (imageData: File, successCallback?: (user: User) => void) => {
      try {
        const formData = new FormData();

        formData.append('avatar', imageData);

        const {
          data: { data },
        } = await api.put('me/avatar', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        const user = getUser() as User;

        user.avatar = data;

        storeUser(user);
        addLoggedUser(user);

        if (successCallback) successCallback(user);
      } catch (error) {
        showError('Ocorreu um erro com a requisição. Tente novamente.');
      }
    },
    [addLoggedUser],
  );

  const removeAvatar = useCallback(
    async (successCallback?: (user: User) => void) => {
      try {
        await api.delete('me/avatar');

        const user = getUser() as User;

        user.avatar = undefined;

        storeUser(user);
        addLoggedUser(user);

        if (successCallback) successCallback(user);
      } catch (error) {
        showError('Ocorreu um erro com a requisição. Tente novamente.');
      }
    },
    [addLoggedUser],
  );

  const update = useCallback(
    async (user: UpdateUserData, successCallback?: (user: User) => void) => {
      try {
        const {
          data: { data },
        } = await api.put('me', user);

        storeUser(data);
        addLoggedUser(data);

        if (successCallback) successCallback(data);
      } catch (error) {
        showError('Ocorreu um erro com a requisição. Tente novamente.');
      }
    },
    [addLoggedUser],
  );

  const mountValue = useMemo(
    () => ({
      passwordRecovery,
      confirmRecovery,
      completeRecovery,
      uploadAvatar,
      removeAvatar,
      update,
    }),
    [
      passwordRecovery,
      confirmRecovery,
      completeRecovery,
      uploadAvatar,
      removeAvatar,
      update,
    ],
  );

  return (
    <UserContext.Provider value={mountValue}>{children}</UserContext.Provider>
  );
}

function useUser(): UserContextData {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }

  return context;
}

export { UserProvider, useUser };
